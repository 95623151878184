import {ApolloLink, from, fromPromise} from '@apollo/client/core';
import {onError} from '@apollo/client/link/error';
import {setContext} from '@apollo/client/link/context';
import {inject, Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {GraphqlErrorCode} from '../graphql/errors';
import {LoggerService} from '../logger.service';

@Injectable({
  providedIn: 'root'
})
export class AuthLink {
  private readonly logger = inject(LoggerService).create('Auth link');

  refresh: Promise<void>;

  constructor(private authService: AuthService) {
  }

  public create(): ApolloLink {
    const authLink = setContext((operation, {headers}) => {
      const accessToken = this.authService.accessToken;
      return {
        headers: {
          ...headers,
          Authorization: accessToken ? `Bearer ${accessToken}` : '',
          'Client-Id': this.authService.clientId,
        },
      };
    });

    const refreshLink = onError(({graphQLErrors, operation, forward}) => {
      const isUnauthorized = graphQLErrors?.find(e => e.extensions?.code === GraphqlErrorCode.Unauthenticated);
      if (isUnauthorized && !!this.authService.refreshToken) {
        if (!this.refresh) {
          this.logger.logInfo('Refresh session');
          this.refresh = new Promise<void>((resolve, reject) => {
            this.authService.refresh()
              .then(() => {
                this.logger.logInfo('Session refreshed');
                resolve();
              })
              .catch(error => {
                this.logger.logError('Session refresh error', {error});
                this.authService.reset();
                reject(error);
              })
              .finally(() => {
                this.refresh = null;
              });
          });
        }

        return fromPromise(this.refresh)
          .flatMap(() => forward(operation));
      }
    });

    return from([refreshLink, authLink]);
  }
}
