import {Injectable} from '@angular/core';
import {filter} from 'rxjs/operators';
import {timer} from 'rxjs';
import {SwUpdate} from '@angular/service-worker';
import {UpdateAvailableDialogComponent} from './update-available-dialog/update-available-dialog.component';
import {Dialog} from '@angular/cdk/dialog';
import {AppService} from '../app.service';
import semverGt from 'semver/functions/gt';

export interface AppData {
  version: string;
  urgent: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  constructor(private updates: SwUpdate,
              private dialog: Dialog,
              private appService: AppService) {
  }

  reload() {
    document.location.reload();
  }

  async checkAndUpdate() {
    if (this.updates.isEnabled) {
      const ready = await this.updates.checkForUpdate();
      if (ready) {
        await this.updates.activateUpdate();
        this.reload();
      }
    }
  }

  startUpdateChecking() {
    if (this.updates.isEnabled) {
      timer(0, 60 * 1000)
        .subscribe(() => this.updates.checkForUpdate());

      this.updates.versionUpdates
        .pipe(
          filter(event => event.type === 'VERSION_READY'),
        )
        .subscribe(event => {
          if (event.type === 'VERSION_READY') {
            this.updates.activateUpdate();

            const available = event.latestVersion.appData as AppData;
            const current = event.currentVersion.appData as AppData;
            if (semverGt(available.version, current.version) && available.urgent) {
              this.update();
            }
          }
        });

      this.updates.unrecoverable.subscribe(() => {
        if (confirm('Произошла ошибка загрузки данных, требуется обновить страницу.')) {
          document.location.reload();
        }
      });
    }
  }

  private update() {
    if (this.appService.isHost) {
      this.showNotifyDialog();
    }
    if (this.appService.isPlayer) {
      this.reload();
    }
  }

  private showNotifyDialog() {
    this.dialog.open(UpdateAvailableDialogComponent);
  }
}
